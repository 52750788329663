import React, { ReactElement } from 'react'

const title = 'Trykkbølgeterapi'

const content = (): ReactElement => {
  return (
    <>
      <p>
        Kiropraktor Akutten Stavanger/Sandnes tilbyr trykkbølgebehandling. Trykkbølgebehandling er samme teknologi som
        benyttes til å knuse blant annet nyrestein.
      </p>

      <p>
        Trykkbølgeapparat sender en rekke små, høyfrekvente slag mot huden som brer seg inn i vevet. Dette setter i gang
        en rekke fysiologiske reaksjoner i vevet som stimulerer kroppens egne reperasjonsprosesser.
      </p>

      <p>
        Kiropraktor Akutten Stavanger/Sandnes har trykkbølgeapparat fra den nyeste serien til Storz, verdens ledene
        produsent av trykkbølgeapparat.
      </p>
    </>
  )
}

export const TRYKKBØLGETERAPI = { title, content: content() }
