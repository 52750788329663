import React, { ReactElement } from 'react'

const title = 'Ischemisk trykk'

const content = (): ReactElement => {
  return (
    <p>
      Kiropraktoren behandler stive muskler ved å lokalisere triggerpunkter i muskelen og gir et manuelt press mot disse
      punktene. Kiropraktoren bryter på denne måten ned sammentrekninger i muskelvevet og muskelen slapper mer av, og
      funksjonen til muskelen blir bedre.
    </p>
  )
}

export const ISCHEMISK_TRYKK = { title, content: content() }
