import React, { ReactElement } from 'react'

const title = 'Fotsmerter'

const content = (): ReactElement => {
  return (
    <>
      <h3>Hva er fotsmerter?</h3>
      <p>
        Fotsmerter er når en har smerter som sitter i foten. Smertene sitter oftest under foten eller i selve
        ankelleddet. Smertene kan komme fra både muskler, sener, muskelhinner («plantarfaschitt») eller slimposer. Noen
        ganger kommer symptomer i foten fra skiveproblemer i korsrygg, da i form av referert smerte på grunn av
        nerverotsirritasjon.
      </p>

      <p>
        Fotsmerter er et problem Kirorpaktor Akutten Stavanger/Sandnes er godt kjent med å behandle. Kiropraktoren
        behandler fot daglig. Om det ikke er på grunn av smerter i selve foten så behandles fot på grunn av den store
        effekten feilbelastning fra foten har på resten av kroppen. Hver behandling varer fra 20-30 minutt.
      </p>
    </>
  )
}

export const FOTSMERTER = { title, content: content() }
