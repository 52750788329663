import React, { ReactElement } from 'react'

const title = 'Migrene'

const content = (): ReactElement => {
  return (
    <>
      <h3>Hva er migrene?</h3>
      <p>
        Migrene er en sterk type hodeverk som kommer som anfall. Det er ofte en ensidig sterk hodepine som er pulserende
        og kan vare fra 4-72 timer. Ofte følger det med kvalme, brekninger, lys- og lydømfintlighet. Migrene kan være
        med eller uten aura (synsforstyrrelser der pasienten ser blinkende lys, svarte flekker eller sikk-sakklinjer i
        ett eller begge synsfelt). Sidelokalisasjon kan variere fra anfall til anfall.
      </p>

      <p>
        Det finnes flere triggerfaktorer til migrene, alt fra spenningshodepine til ulike matvarer, ulike legemidler,
        stress, hormonsvingninger, alkohol etc. Spenningshodepinen er er av de vanligste triggerfaktorene og det er
        dette en kiropraktor kan hjelpe deg med. Med å behandle spenningshodepinen vil man ta bort eller redusere denne
        triggerfaktoren. Kiropraktor Akutten har veldig god erfaring med og god effekt på behandling av migrene på denne
        måten. Hver behandling varer fra 20-30 minutt.
      </p>
    </>
  )
}

export const MIGRENE = { title, content: content() }
