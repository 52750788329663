import React, { ReactElement } from 'react'

const title = 'Senebetennelse'

const content = (): ReactElement => {
  return (
    <>
      <h3>Hva er senebetennelse?</h3>
      <p>
        Senebetennelser er en betennelse/innflammasjon i senen til en muskel, det vil si der muskelen fester inn i
        skjelettet vårt. Senebetennelse kan oppstå i så og si hvilken som helst muskel i kroppen vår. Når en muskel blir
        overbelastet oppstår triggerpunkter i muskelen og muskelfibrene forkortes/trekkes sammen. Senebetennelse kjennes
        som en lokal verkende smerte som blir verre ved belastning og det kan kjennes bedre om muskelen får hvile.
      </p>

      <p>
        Senebetennelse oppstår ofte i underarmer, bein (akillessenen), knær, lysk, skuldre, men kan oppstå hvor som
        helst. Det er ingen typisk pasient med senebetennelse. Det kan for eksempel oppstå i underarmen på
        kontorarbeideren som sitter for mye og trykker på tastaturet, det kan oppstå kan oppstå i lysk eller kne på
        fotballspilleren som har overbelastet musklene i låret etter intense treningsperioder, eller det kan oppstå i
        albuen til hobbytennisspilleren. Det hender også at sene-/muskelfibre blir revet over, noe som betegnes som en
        partiell ruptur. For tidlig retur til idrettsaktiviteter etter en slik partiell ruptur er en annen årsak til
        senebetennelse.
      </p>

      <p>
        Senebetennelser kan bli bra ganske raskt. Med trykkbølgebehandling som supplement til vanlig behandling kan det
        bli bra på et par uker, men jo lengre en har hatt problemet jo lengre tid kan det ta å bli helt frisk. Det er
        alltid lurt ved smerter fra muskel og skjelett å få behandling raskest mulig, spesielt ved senebetennelser. Vi
        har god erfaring med behandling av senebetennselser. Kombinasjonen av DNS, trykkbølgebehandling/nålebehandling,
        ART og kiropraktisk leddjustering er veldig effektivt på slike problemer. Hver behandling varer fra 20-30
        minutt.
      </p>
    </>
  )
}

export const SENEBETENNELSE = { title, content: content() }
