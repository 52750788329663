import React, { ReactElement } from 'react'

const title = 'Nålebehandling'

const content = (): ReactElement => {
  return (
    <p>
      Nålebehandling Kiropraktoren bruker nålebehandling dersom det er nødvendig for å få stive muskler til å slappe mer
      av. Dette gjøres ved at kiropraktoren lokaliserer triggerpunkt i muskelen og stikker en tynn nål i dette punktet.
      Om kiropraktoren treffer får pasienten en liten rykning i muskelfibrene og muskelen slapper mer av. Dette oppleves
      av de aller fleste som ikke mer enn et mildt ubehag.
    </p>
  )
}

export const NÅLEBEHANDLING = { title, content: content() }
