import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const title = 'Spedbarn, barn og ungdom'

const content = (): ReactElement => {
  return (
    <>
      <h3>Spedbarn</h3>
      <p>
        Problemer med ryggen hos spedbarn er vanligere enn hva mange tror. Det kan være vanskelig å vite om barnet ditt
        har problemer med ryggen, men dersom man mistenker det så er det lurt å gå til kiropraktor for å få en kontroll.
        Typiske tegn er mye grining, dårlig søvn og generell urolighet. Selve behandlingen er helt ufarlig og
        kiropraktoren bruker ikke sterkere kraft enn hva du selv kan trykke deg på et lukket øye uten at det gjør vondt.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <ul>
          Hva behandler vi?
          <li>Kolikklignende symptomer (generell urolighet)</li>
          <li>KISS/KID</li>
          <li>Favorittside</li>
          <li>Motoriske problemer</li>
          <li>Kontroll av normal motorisk utvikling</li>
          <li>CCD, central coordination disturbance</li>
        </ul>
        <StaticImage
          src="../../images/shutterstock_280436912_web.png"
          alt="Spedbarn"
          layout="fullWidth"
          className="md:mt-4 h-[240px] rounded-lg shadow-md"
          imgClassName="rounded-lg"
        />
      </div>
      <p>
        Typiske årsaker som kan gi noen av problemene listet ovenfor er om barnet er tatt med vakum eller tang, om
        barnet har ligget trangt i livmoren, keisersnitt eller om fødselen har vært unormalt langvarig.
      </p>
      <p>
        Det første leveåret, og spesielt de første 9 månedene, er veldig viktig for motorikken vår og til og med formen
        på skjelettet vårt resten av livet.
      </p>
      <p>
        Om man har problemer med leddlåsninger eller dårlig utviklet motorikk så tidlig i livet så vil dette kunne gjøre
        at man er mer utsatt for muskel- og skjelettproblemer senere i livet.
      </p>
      <h3>Spisskompetanse</h3>
      <p>Kiropraktor Akutten Stavanger/Sandnes har spisskompetanse innen behandling av barn.</p>
      <p>I tillegg til å gjøre kiropraktisk behandling av barn gjør vi DNS behandling av barn.</p>
      <p>
        Det innebærer at vi kjenner alle nødvendige primitive reflekser og posturale reaksjoner, det vil si tester på om
        den motoriske utviklingen utvikler seg normalt.
      </p>
      <p>
        Vi kjenner også alle de motoriske utviklingsposisjonene, og hvordan muskelaktiveringen skal se ut i disse, som
        en baby går igjennom fra for eksempel 3 måneders mageliggende og ryggliggende til kryping ved ni måneder og til
        motorikken er så utviklet at man kan gå ved 12-14 måneder.
      </p>
      <p>
        Om babyen gjør noen av disse posisjonene med dårlig kvalitet på muskelaktiveringen eller om babyen gjør
        posisjonen for sent eller kanskje ikke i det hele tatt så indikerer dette tilstanden CCD, central coordination
        disturbance. Dette er ikke en farlig tilstand, men mer en beskrivelse på at den motoriske utviklingen ikke er
        optimal. Dette kan behandles ved å plassere babyen i den posisjonen som ikke gjøres på rett måte eller som
        babyen har hoppet over samtidig som kiropraktoren bruker stimulerende trykk på områder med større tetthet av
        nerveceller.
      </p>
      <p>
        Disse sonene har under utviklingen av motorikken kontakt med bakken og stimuleres av tyngdekraften. Dette driver
        aktiveringen av muskelsystemet vårt fra hjernen. Ved at kiropraktoren stimulerer disse sonene stimuleres hjernen
        sin aktivering av disse viktige kjernemusklene. Denne teknikken er en del av DNS (dynamisk nevromuskulær
        stabilisering) og kalles «Reflex Locomotion».
      </p>
      <StaticImage
        src="../../images/shutterstock_337725356_web.png"
        alt="Barn leker"
        layout="fullWidth"
        className="mx-auto max-w-md rounded-lg shadow-md"
        imgClassName="rounded-lg"
      />
      <h3>Barn og ungdom</h3>
      <p>
        I tidlig barn- og ungdomsår utvikler grov og fin motorikk seg videre. Skjelettet er fortsatt formbart og man
        utvikler mye av våre atferdsmønster.
      </p>
      <p>
        Har man leddlåsninger og dårlig stabiliserende muskelfunksjon kan dette lede til dårligere holdning og feil
        belastning på ankler, korsrygg, brystrygg og nakke.
      </p>
      <p>
        Smerter kan oppstå fra alle disse områdene. Det er derfor viktig å behandle dette for å få et så bra
        belastningsmønster på rygg og føtter som mulig og dette vil minske risikoen for muskel- og skjelettproblemer
        senere i livet.
      </p>
      <p>
        Holdningsproblemer forsterkes i skolealder, da barna sitter større deler av dagen. Dette kan bidra til stivhet i
        ledd og muskler. Samtidig er det vanlig etter hvert som karakterer og prøver får større betydning at stress blir
        en større påvirkning på muskel- og skjelettsystemet.
      </p>
      <p>
        Kroppen reagerer ubevisst på stress via «fight or flight» mekanismen. Det innebærer blant annet at man puster
        mer med muskler i nakke og brystrygg for å få inn mer oksygen og er en evolusjonsmessig veldig fordelaktig
        mekanisme med tanke på overlevelse.
      </p>
      <p>
        Problemet i dagens samfunn er at man er mer kronisk stresset, med andre ord over lengre perioder. Stresset kan
        blant annet komme fra press til å prestere eller at man føler man ikke presterer bra nok. Dette kroniske
        stresset kan gradvis forandre måten vi puster på.
      </p>
      <p>
        Normal fysiologisk pustemønster er at vi puster med magen, dvs. vi puster med mellomgulvet (m diaphragma) som er
        en stor kuppelformet muskel som fester under lungene våre. Dårlig funksjon gir dårligere stabilitet i
        mage/korsryggregionen, dårligere holdning, og bidrar ofte til leddlåsninger og stivhet i brystrygg.
      </p>
      <p>
        Å behandle disse problemene vil både ta bort eventuelle smerter og også redusere risiko for større problemer
        senere i livet.
      </p>
    </>
  )
}

export const BARN = { title, content: content() }
