import React, { ReactElement } from 'react'

const title = 'Spenningshodepine'

const content = (): ReactElement => {
  return (
    <>
      <h3>Hva er spenningshodepine?</h3>
      <p>
        Spenningshodepine er en av de vanligste årsakene til at folk oppsøker kiropraktor. Det vi si at dette er noe vi
        behandler daglig og har veldig god erfaring med. Hver behandling varer fra 20-30 minutt.
      </p>

      <p>
        Spenningshodepine er et problem som oftest kommer fra en irritasjon av nerver i nakke. Dette kan være spente
        muskler eller låste ledd som kiropraktoren effektivt kan behandle. Det finnes flere typer hodeverk, men
        spenningshodepine er den aller vanligste. Kiropraktoren har kunnskap til å skille mellom vanlig
        spenningshodepine og hodeverk av andre årsaker. Spenningshodepine kjennes ofte i fremre del av hodet, i
        tinninger, men kan også kjennes bak i hodet. Det kan føles som et trykk i hodet eller bak øynene. Smerten kan
        være sammen med, eller uten, smerter i nakke, da spesielt øvre del, eller med en følelse av stivhet i
        skulder-nakke regionen.
      </p>
    </>
  )
}

export const SPENNINGSHODEPINE = { title, content: content() }
