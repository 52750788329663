import React, { ReactElement } from 'react'

const title = 'Skiveutglidning'

const content = (): ReactElement => {
  return (
    <>
      <h3>Hva er skiveutglidning?</h3>

      <p>
        Skiveproblemer er en veldig vanlig årsak til smerter i korsrygg og/eller nakke. Det vil si at dette er noe vi
        behandler daglig og har veldig god erfaring med. Behandlingen handler om å avlaste området skiveproblemet sitter
        ved å få bedre bevegelighet i leddene over og under skiveproblemet, og ved å skape bedre styrke og kontroll på
        musklene som skal stabilisere områdene der skiveproblemet sitter. Hver behandling varer fra 20-30 minutt.
      </p>

      <p>
        I mellom ryggvirvlene våre har vi skiver. De er til for næringsforsyning og for at ryggvirvlene skal kunne
        bevege seg mot hverandre. Skivene består av en gelemasse som er innkapslet av fibrøst vev. Vi overbelaster ofte
        nedre del av nakke og nedre del av korsrygg på grunn av at vi lever et liv som gjør at vi lett stivner til i
        brystrygg, øvre nakke og hofter ved for eksempel for mye sitting og for lite bevegelse eller for mye
        ståing/gåing/springing på hardt underlag. Skivene i nedre nakke og nedre korsrygg blir da slitt. Slitte skiver
        kan lett sprekke opp ved feil bevegelse og belastning. Dette resulterer i bukende skiver eller prolapser som kan
        presse mot nerver og gi utstrålende smerter i armer eller bein.
      </p>
    </>
  )
}

export const SKIVEUTGLIDNING = { title, content: content() }
