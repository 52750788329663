import React, { ReactElement } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface AccordionProps {
  title: string
  content: ReactElement
}

export default function Accordion({ title, content }: AccordionProps): ReactElement {
  return (
    <Disclosure as="div" key={title} className="pt-6">
      {({ open }) => (
        <div className={classNames(open ? 'bg-white shadow-lg rounded-lg' : '', 'transform duration-300')}>
          <dt className="text-lg">
            <Disclosure.Button
              className={`rounded-t-lg px-4 py-2 flex justify-between items-start w-full text-left text-gray-400 ${
                open && 'bg-primary-light bg-opacity-10'
              }`}
            >
              <span className={`font-medium ${open ? 'text-primary' : 'text-gray-900'}`}>{title}</span>
              <span className="flex items-center ml-6 h-7">
                <ChevronDownIcon
                  className={classNames(
                    open ? '-rotate-180 text-primary' : 'rotate-0',
                    'h-6 w-6 transform duration-300'
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="px-4 pb-2">
            <div className="pt-4 text-base text-gray-500 Accordion-Content">{content}</div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
