import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const title = 'Behandling for gravide'

const content = (): ReactElement => {
  return (
    <>
      <p>
        Hos Kiropraktor Akutten Stavanger/Sandnes har vi lang erfaring med behandling av gravide med veldig gode
        resultater. Det er ikke farlig med behandling selv om man er gravid.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div className="md:col-span-3">
          <h3 className="!mt-4">Vanlige problemområder hos gravide</h3>
          <p>
            Når man er gravid er det vanlig å få smerter i brystrygg, bekken, hofter og korsrygg. Smertene fra
            korsryggen kan også stråle ned i lår, legger, og/eller føtter. Noen opplever også spenningshodepine som en
            konsekvens av endringen av kroppens tyngdepunkt som kan gi økte spenninger i muskler og leddlåsninger også i
            nakke.
          </p>
        </div>
        <StaticImage
          src="../../images/shutterstock_146442911.jpg"
          alt="Gravide kvinne"
          layout="fullWidth"
          className="md:col-span-2 rounded-lg shadow-md"
          imgClassName="rounded-lg"
        />
      </div>

      <h3>Hvorfor trenger man behandling når man er gravid?</h3>
      <p>
        Tidlig under første trimester starter kroppen å produsere hormonet relaxin og allerede ved uke 14 er nivået av
        relaxin på topp.
      </p>

      <p>
        Dette hormonet har en rekke viktige funksjoner i forhold til graviditet. Det øker hjerterytmen for å pumpe mer
        blod i kroppen og det sørger for mer transport av blod gjennom nyrene for rense blodet som skal til livmoren. En
        annen funksjon som er mye mer relevant til bekkenproblemer er at det gir bekkenløsning. Dette er en nødvendighet
        for at leddbånd i bekken skal bli løsere og gi mer etter i forbindelse med fødselen. Ofte kan det også skape
        smerter i bekkenledd og/eller korsrygg.
      </p>

      <p>
        Kroppen er veldig god på å kompensere. Om et leddområde (f.eks brystrygg) begynner å bevege seg dårlig tar et
        annet over og beveger seg tilsvarende mye mer (korsrygg) for at vi globalt sett skal kunne fungere best mulig.
        En muskel blir svak og en annen vil ta over å jobbe tilsvarende mye mer.
      </p>

      <p>
        Dette kan være en kompensasjon som fungerer en stund og som regel går det ganske lang tid før det resulterer i
        smerter. En illustrasjon kan være at kroppen er som et vannglass, det kan være ganske fullt uten at det renner
        over. For gravide er det ofte slik, noen klarer en graviditet med få eller ingen problemer, mens andre får store
        problemer uten nødvendigvis å ha hatt problemer før graviditeten.
      </p>

      <p>
        Dette er vannglasset som har vært ganske fullt, men kroppen har kompensert for så at graviditet med forandret
        tyngdepunkt, holdning og endret hormonproduksjon får glasset til å renne over. Resultatet er smerter.
      </p>

      <p>
        Når man er gravid forskyves tyngdepunktet til kroppen fremover fra å gå igjennom fremre halvdel av halebeinet.
        Dette gir økt belastning på korsrygg og bekken i henhold til vektarmsprinsippet. Holdningen blir ofte dårlig som
        en konsekvens av dette. Man svaier mer i korsryggen og blir krummere i brystryggen. Dette leder igjen til
        leddlåsninger og kompensasjoner for leddlåsninger der enkelte ledd beveger seg kompensatorisk for mye.
      </p>

      <p>
        Her kan kiropraktoren hjelpe mye med å skape bevegelse i de låste leddene som igjen vil avlaste de leddene som
        beveger seg for mye. Kiropraktoren vil også kunne hjelpe deg med å finne de øvelsene som passer deg best for å
        styrke de rette musklene for å stabilisere bedre og hjelpe med holdningen.
      </p>

      <p>Behandlingen fra kiropraktoren vil som regel allerede ved første behandling dempe smertene.</p>
    </>
  )
}

export const GRAVIDE = { title, content: content() }
