import React, { ReactElement } from 'react'

const title = 'Hofteplager'

const content = (): ReactElement => {
  return (
    <>
      <h3>Hva er hofteplager?</h3>
      <p>
        Hofteplager er når en har smerter typisk i eller rundt hoftene. Smertene kan være lokale i hofteleddet eller
        stråle ned mot kne eller i noen tilfeller opp mot korsrygg. Det kan forekomme smerter i lysken og foran og på
        siden av låret. Smertene kan stråle ned mot kneet. Smertene kommer ofte når man skal begynne å gå. De blir
        mindre intense etter at du har gått noen sekunder eller minutter, men blir verre igjen etter en tids gåing. Mye
        belastning på bena øker plagene. Etter hvert kan det utvikles smerter i hvile og om natten. De smertegivende
        strukturene er enten selve hofteleddet, muskler, sener eller slimpose i hoften.
      </p>

      <p>
        Hofteledd er utsatte ledd og blir lett stive pga mye belastning. De fleste av oss sitter store deler av dagen,
        om det er på jobb, på vei til og fra jobb, på spinningsykkelen på trening eller når vi kommer hjem og setter oss
        i sofaen. Dette gir over tid korthet og stramhet i musklene som bøyer hoften. Dette vil igjen gjøre at selve
        hofteleddet stivner til og etter en stund vil en eller annen struktur si fra og generere smerte.
      </p>

      <p>
        Hoftesmerter er en vanlig årsak til å oppsøke kiropraktor. Det vi si at dette er noe vi behandler ofte og har
        veldig god erfaring med. Hver behandling varer fra 20-30 minutt.
      </p>
    </>
  )
}

export const HOFTEPLAGER = { title, content: content() }
