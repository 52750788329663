import React, { ReactElement } from 'react'

import Layout from '../components/layout'

import Accordion from '@/components/accordion'
import { DNS } from '@/components/behandlingsmetoder/dns'
import { ISCHEMISK_TRYKK } from '@/components/behandlingsmetoder/ischemisk-trykk'
import { KLASSISK_REHABILITERING } from '@/components/behandlingsmetoder/klassisk-rehabilitering'
import { LEDDJUSTERING } from '@/components/behandlingsmetoder/leddjustering'
import { MDT } from '@/components/behandlingsmetoder/mdt'
import { NÅLEBEHANDLING } from '@/components/behandlingsmetoder/naalebehandling'
import { TRYKKBØLGETERAPI } from '@/components/behandlingsmetoder/trykkbolgeterapi'
import { BEKKENSMERTER } from '@/components/behandlingstilbud/bekkensmerter-bekkenlosning'
import { BRYSTRYGGSMERTER } from '@/components/behandlingstilbud/brystryggsmerter'
import { FOTSMERTER } from '@/components/behandlingstilbud/fotsmerter'
import { GRAVIDE } from '@/components/behandlingstilbud/gravide'
import { HOFTEPLAGER } from '@/components/behandlingstilbud/hofteplager'
import { KNEPLAGER } from '@/components/behandlingstilbud/kneplager'
import { KORSRYGGSMERTER } from '@/components/behandlingstilbud/korsryggsmerter'
import { MIGRENE } from '@/components/behandlingstilbud/migrene'
import { NAKKESMERTER } from '@/components/behandlingstilbud/nakkesmerter'
import { OVERTRÅKK } from '@/components/behandlingstilbud/overtraakk'
import { SENEBETENNELSE } from '@/components/behandlingstilbud/senebetennelse'
import { SKIVEUTGLIDNING } from '@/components/behandlingstilbud/skiveutglidning'
import { SKULDERSMERTER } from '@/components/behandlingstilbud/skuldersmerter'
import { BARN } from '@/components/behandlingstilbud/spedbarn-barn-og-ungdom'
import { SPENNINGSHODEPINE } from '@/components/behandlingstilbud/spenningshodepine'
import { UTSTRÅLENDE_SMERTER } from '@/components/behandlingstilbud/utstralende-smerter'
// markup
const Page = (): ReactElement => {
  return (
    <Layout>
      <h1 className="!mb-0">Behandling</h1>

      <section>
        <h2 className="!mt-8 !mb-1">Behandlingstilbud</h2>
        <div>
          <Accordion {...GRAVIDE} />
          <Accordion {...BARN} />
          <Accordion {...BEKKENSMERTER} />
          <Accordion {...BRYSTRYGGSMERTER} />
          <Accordion {...HOFTEPLAGER} />
          <Accordion {...KNEPLAGER} />
          <Accordion {...FOTSMERTER} />
          <Accordion {...KORSRYGGSMERTER} />
          <Accordion {...MIGRENE} />
          <Accordion {...SPENNINGSHODEPINE} />
          <Accordion {...NAKKESMERTER} />
          <Accordion {...OVERTRÅKK} />
          <Accordion {...SENEBETENNELSE} />
          <Accordion {...SKIVEUTGLIDNING} />
          <Accordion {...SKULDERSMERTER} />
          <Accordion {...UTSTRÅLENDE_SMERTER} />
        </div>
      </section>
      <section className="pt-4 mt-4 border-t border-gray-200 border-solid">
        <h2 className="!my-1">Behandlingsmetoder</h2>
        <div>
          <Accordion {...DNS} />
          <Accordion {...MDT} />
          <Accordion {...ISCHEMISK_TRYKK} />
          <Accordion {...KLASSISK_REHABILITERING} />
          <Accordion {...LEDDJUSTERING} />
          <Accordion {...NÅLEBEHANDLING} />
          <Accordion {...TRYKKBØLGETERAPI} />
        </div>
      </section>
    </Layout>
  )
}

export default Page
