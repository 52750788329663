import React, { ReactElement } from 'react'

const title = 'Leddjustering'

const content = (): ReactElement => {
  return (
    <p>
      Å justere ledd er hammeren i verktøykassen til kiropraktoren. Ofte skyldes smerter og problemer at leddene beveger
      seg for mye («hypermobile») eller at de beveger seg for lite («hypomobile»/«låst»). Ofte er dette kombinert, da
      kroppen kompenserer for stivhet i ledd med å ta ut mer bevegelse fra andre ledd i nærheten. Kiropraktoren kan
      skape bedre bevegelighet i låste ledd ved å gi en hurtig impuls mot leddet. Man bryter da bindinger i vevet i
      leddet og dette øker leddbevegeligheten. På denne måten kan kiropraktoren få stive ledd til å begynne å bevege seg
      bedre, og dette vil igjen bidra til at ledd som beveger seg for mye også vil normalisere bevegeligheten.
    </p>
  )
}

export const LEDDJUSTERING = { title, content: content() }
