import React, { ReactElement } from 'react'

const title = 'Klassisk rehabilitering'

const content = (): ReactElement => {
  return (
    <p>
      Kiropraktoren vil ved behov bruke ulike øvelser i behandlingen for å styrke viktige muskler og skape bevegelighet
      i ledd.
    </p>
  )
}

export const KLASSISK_REHABILITERING = { title, content: content() }
